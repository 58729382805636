import Vue from 'vue'
import VueRouter from 'vue-router'
// import layout from '@/components/layout/index.vue'

// import integratedData from '@/views/integratedData/index.vue'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'home',
	// component: () => import('../views/index.vue')
	meta: {
		title: '首页'
	},
	component: () => import( /* webpackChunkName: "home" */ '@/views/index.vue'),
	redirect: '/integrated',
	children: [{
		path: 'integrated',
		name: 'integrated',
		meta: {
			title: '健身锻炼综合数据'
		},
		component: () => import( /* webpackChunkName: "IntegratedData" */
			'@/views/integratedData/index.vue')
	},
	]
}]
const router = new VueRouter({
	routes
})

export default router
