<template>
  <div id="app">
    <!-- <app-home></app-home> -->
    <router-view />
  </div>
</template>

<script>
// import Home from '@/views/home/index.vue'
export default {
  name: 'App',
  // components: {
  //   'app-home': Home
  // }
}
</script>

<style lang="scss" scoped>
@import url(./assets/iconfont/iconfont.css);

#app {
  width: 100vw;
  height: 100vh;
  background-color: #010721;
  overflow: hidden;
}
</style>
